.header-color {
  background: #00c6ff;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #0072ff, #00c6ff);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #0072ff, #00c6ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* Landing page */
.landing-grid {
  background: #d9d9d9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #d9d9d9, #d9d9d9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FFFFFF, #d9d9d9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  /* position:  fixed;  */
  display: flex; 
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;
}

.profil_picture {
  height: 350px;
}

.banner-text {
  
  opacity: .8;
  width: 50%; 
  margin: auto;  
  border-radius: 10px; 

}

.banner-text h1 {
  font-size: 66px; 
  font-weight: bold; 
  color: #000000; 
}

.banner-text hr {
  border-top: 5px dotted white; 
  width: 50%; 
  margin: auto; 
}

.banner-text p {
  color: #000000; 
  font-family: 'Josefin Slab', serif;
  font-size: 20px; 
  font-weight: bold;
}

.social-links {
  display: flex; 
  justify-content: space-between; 
  width: 50%; 
  margin: auto; 
}

.social-links i {
  color: #38b6ff; 
  font-size: 2em; 
  padding: 4px; 
}

/* Resume page*/

.resume-page {
  background: #d9d9d9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #d9d9d9, #d9d9d9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FFFFFF, #d9d9d9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-family:  'Josefin Slab', serif;
  font-weight: 'bold';
  font-size: '40px';
}

.resume-right-col {
  background: #00c6ff;               /* Color of the right col background*/
  color: white; 
  padding: 2em;
}

.resume-left-col {             
  color: black;
}

/* .resume-div p {
  font-family:  'Josefin Slab', serif;
  font-weight: 'bold';
} */

/* Resume page*/

/* About Me*/

.aboutMe-page {
  background: #d9d9d9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #d9d9d9, #d9d9d9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FFFFFF, #d9d9d9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  height: 100%;
  width: 100%;
}

.aboutMe-cell {
  font-family: 'Acme', sans-serif;
  padding-left: 10rem;
  text-align: center;
}

.aboutMe-page h1 {
  font-family: 'Tangerine', cursive;
  font-weight: bold;
  text-align: center;
  padding-left: 20rem;
}

.aboutMe-para1 {
  font-family:  'Josefin Slab', serif;
  font-size: 40%;
  width: 100%;
  font-weight: bold;
  padding-left: 10rem;
  padding-right: 10rem;
}

/* About Me*/

/* Project page*/

.project-page {
  background: #d9d9d9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #d9d9d9, #d9d9d9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FFFFFF, #d9d9d9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100%;
  width: 100%;
}

.project-card1 {
  width: 70%;
  padding-left: 6rem;
  padding-top: 1rem;
}

.project-card3 {
  width: 70%;
  padding-left: 6rem;
  padding-top: 2rem; 
}

.project-card2 {
  width: 70%;
  padding-top: 1rem; 
}

.project-card4 {
  width: 70%;
  padding-top: 1.90rem;
}

.project-card1 h6 {
  font-size: 120%;
  font-family: 'Josefin Slab', serif;
}

.project-card2 h6 {
  font-size: 110%;
  font-family: 'Josefin Slab', serif;
}

/* Project page*/

/* Contact page*/

.contact-body {
  background: #d9d9d9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #d9d9d9, #d9d9d9);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FFFFFF, #d9d9d9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin: auto; 
  width: 100%; 
  height: 100%;
  justify-content: center;
}

.contact-grid {
  text-align: center; 
  justify-content: center; 
  width: 80%; 
  height: 550px;
  background: white; 
  color: #1794ce; 
  margin-bottom: 20px; 
}

.contact-grid h2 {
  font-family: 'Josefin Slab', serif;
  color: #ffe767; 
  right: '2em' ;
}

.contact-grid p {
  font-family: 'Josefin Slab', serif;;
  font-weight: bold; 
}

.contact-grid hr {
  border-top: 5px dotted #ffe767; 
  width: 50%; 
  margin: auto; 
}

.contact-list i {
  font-size: 66px; 
  padding-right: 5rem; 
}

.contact-list a {
  font-family: 'Josefin Slab', serif;
  font-weight: bold; 
}

/* Contact page */

/* Invitation Flyer */

.invitation-header {
  font-family: 'Great Vibes', cursive;
  font-size: 48px;
  color: #27314F;
}

.invitation-subtext {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #8D8D8D;
}

.date-location {
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  background-color: #8B2D25;
  color: #FFD700;
  padding: 10px;
}

.important-note {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #8D8D8D;
}
